<div class="modal-wrapper p-4" [formGroup]="expenseForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Add Expense</h2>
      <p class="textXS mt-2 text">Enter details for add expenses</p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Account <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select
          placeholder="select"
          formControlName="accountID"
          *ngIf="accountList"
          (valueChange)="onAccountSelect($event)"
        >
          <mat-option
            *ngFor="let account of accountList"
            [value]="account.accountID"
            >{{ account.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col s12 m6 l6">
      <p class="mat-body-1 text mb-1 input-label">
        Expense Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput placeholder="Enter here" formControlName="name" />
      </mat-form-field>
      <div
        *ngIf="
        expenseForm.get('name')?.touched || expenseForm.get('name')?.dirty
      "
      >
        <div
          class="required-star mt-1"
          *ngIf="
          !expenseForm.get('name')?.hasError('required') &&
          expenseForm.get('name')?.hasError('whitespace')
        "
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>
    <div class="mb-5 mt-5">
      <span class="textXS">Expense type</span>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="type"
        (change)="onTypeChange()"
      >
        <mat-radio-button value="one_time" [checked]="true"
          >One Time</mat-radio-button
        >
        <mat-radio-button value="recurring" [checked]="false"
          >Recurring</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="row">
      <div class="col s12 m6 l6">
        <p class="mat-body-1 text mb-1 input-label">
          Quantity <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            placeholder="Enter here"
            formControlName="quantity"
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('quantity', $event)
            "
          />
        </mat-form-field>
        <span class="required-star" *ngIf="!numericInputValidity['quantity']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            expenseForm.get('quantity')?.invalid &&
            (expenseForm.get('quantity')?.touched ||
              expenseForm.get('quantity')?.dirty) && numericInputValidity['quantity']
          "
        >
          Field is required.
        </span>
      </div>
      <div class="col s12 m6 l6">
        <p class="mat-body-1 text mb-1 input-label">
          Expense Amount <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            placeholder="Enter here"
            formControlName="expenseAmount"
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange('expenseAmount', $event)
            "
          />
        </mat-form-field>
        <span
          class="required-star"
          *ngIf="!numericInputValidity['expenseAmount']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            expenseForm.get('expenseAmount')?.invalid &&
            (expenseForm.get('expenseAmount')?.touched ||
              expenseForm.get('expenseAmount')?.dirty) &&  numericInputValidity['expenseAmount']
          "
        >
          Field is required.
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : saveExpense()"
    ></sft-button>
  </div>
</div>
