import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable, Subject, tap } from 'rxjs';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { ExpenseService } from 'src/app/admin-portal/modules/expense-detail/services/expense.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';
import { SnackBarComponent } from 'src/shared/components/snack-bar/snack-bar.component';

@Component({
  selector: 'app-add-expense-from-layout',
  templateUrl: './expense-dialog-in-layout.html',
})
export class ExpenseDialogInLayoutComponent {
  expenseForm!: FormGroup;
  expenseList: any[] = [];
  id!: string;
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  accountList: any[] = [];
  selectedAccount: any;

  constructor(
    public matDialog: MatDialogRef<ExpenseDialogInLayoutComponent>,
    private formBuilder: FormBuilder,
    private expenseService: ExpenseService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  private unsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.initializeForm();
    this.loadAccountList().subscribe();
  }

  onTypeChange() {
    this.expenseForm.value;
  }

  initializeForm(initialType: string = 'one_time') {
    this.expenseForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator()]],
      type: [initialType, Validators.required],
      quantity: ['', Validators.required],
      expenseAmount: ['', Validators.required],
      accountID: ['', Validators.required],
    });

    this.expenseForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.expenseForm.invalid || !this.expenseForm.dirty;
    });

    this.isButtonDisabled = true;
  }

  loadAccountList(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accountList = response.data.records;
        })
      );
  }

  onAccountSelect(accountId: string): void {
    this.selectedAccount = this.accountList.find(
      (account) => account.accountID === accountId
    );
  }

  openSnackBar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = 'top';
    config.panelClass = 'success-snackbar';
    config.data = { message: message };

    this.snackBar.openFromComponent(SnackBarComponent, config);
  }

  saveExpense() {
    if (this.expenseForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const newExpenseData = {
        ...this.expenseForm.value,
        accountID: this.expenseForm.get('accountID')?.value,
      };

      this.expenseService.createExpense(newExpenseData).subscribe({
        next: () => {
          this.accountService
            .getAccountById(newExpenseData.accountID)
            .subscribe({
              next: (account: any) => {
                const accountName = account.data.name;
                if (accountName) {
                  const message = `Expense saved to ${accountName}'s account.`;
                  this.openSnackBar(message);
                } else {
                  console.error('Account name is undefined');
                }
              },
              error: (error: any) => {
                console.error('Error fetching account name:', error);
              },
            });
          this.matDialog.close({ success: true });
        },
        error: (error: any) => {},
      });
    } else {
      this.markFormFieldsAsTouched(this.expenseForm);
    }
  }

  markFormFieldsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormFieldsAsTouched(control);
      }
    });
  }

  numericInputValidity: { [key: string]: boolean } = {
    quantity: true,
    expenseAmount: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
