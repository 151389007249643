import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  baseUrl = environment.apiUrl;
  constructor(private apiservice: ApiService) {}

  createExpense(formData: any): Observable<any> {
    let path = `${this.baseUrl}/re-occurring-expense`;
    return this.apiservice.post(path, formData);
  }

  getExpenseList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: 'createdAt',
    orderBy: 'ASC' | 'DESC'
  ): Observable<any> {
    const path = `${this.baseUrl}/re-occurring-expense/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  getExpenseListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/re-occurring-expense/list?accountId=${accountId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  getExpenseById(id: string): Observable<any> {
    const path = `${this.baseUrl}/re-occurring-expense/${id}`;
    return this.apiservice.get(path);
  }

  updateExpense(expenseData: any): Observable<any> {
    const url = `${this.baseUrl}/re-occurring-expense`;
    return this.apiservice.put(url, expenseData).pipe(
      catchError((error: any) => {
        console.error('Error updating expense:', error);
        throw error;
      })
    );
  }

  deleteExpense(id: string) {
    const path = `${this.baseUrl}/re-occurring-expense/${id}`;
    return this.apiservice.delete(path);
  }
}
