<mat-sidenav-container
  class="softobiz-sidenav"
  autosize
  [class.side-nav-open]="isActive"
>
  <mat-sidenav
    #sidenav
    mode="side"
    #drawer
    (openedChange)="onDrawerChange($event)"
  >
    <app-sidebar
      (newItemEvent)="handleToggleSidebar($event)"
      [sidebarOpen]="isSidebarOpen"
    ></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="unset">
    <mat-toolbar class="header-wrapper flex-between">
      <button
        (click)="toggleSidebarAndClass($event)"
        [class.active]="isActive"
        mat-icon-button
        class="example-icon"
        aria-label="Example icon-button with menu icon"
      >
        <img src="/images/menu-toggle.png" alt="profile" />
      </button>

      <div class="right-side-wrapper d-flex align-center">
        <button
          mat-fab
          color="primary"
          [matMenuTriggerFor]="menu"
          class="small mr-6"
          aria-label="Example icon button with a delete icon"
        >
          <mat-icon>add</mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="add-dashboard-shortcut">
          <button mat-menu-item (click)="addAccount()">
            <span>Add Account</span>
          </button>
          <button mat-menu-item (click)="openAddExpenseDialog()">
            <span>Add Re-Occurring Expense</span>
          </button>
          <button mat-menu-item (click)="openAddCostCentreDialog()">
            <span>Add Cost Center</span>
          </button>
          <button mat-menu-item (click)="openAddProjectDialog()">
            <span>Add Project</span>
          </button>
          <button mat-menu-item (click)="openAddResourceDialog()">
            <span>Add Resource</span>
          </button>
          <button mat-menu-item (click)="openAddUserDialog()">
            <span>Add User</span>
          </button>
        </mat-menu>

        <!-- <mat-icon
          matBadge="4"
          matBadgeColor="warn"
          matBadgeSize="small"
          class="material-symbols-outlined mr-8 ml-2"
          >notifications</mat-icon
        > -->

        <button mat-button [matMenuTriggerFor]="profileMenu" class="py-2">
          <div class="d-flex align-center">
            <div class="avatar mr-2">
              <img
                src="/images/profile.jpg"
                alt="profile"
                *ngIf="!userName; else defaultLogo"
              />
              <ng-template #defaultLogo>
                <div
                  class="avatar mr-2"
                  style="
                    background-color: #005F8E;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  {{ getDefaultPhotoText(userName) }}
                </div>
              </ng-template>
            </div>
            <div class="text-left mr-2">
              <p class="textS text mb-0">{{ userName | titlecase }}</p>
              <p class="textXS grey-text">
                {{ portalType === "admin" ? "Admin" : portalType }}
              </p>
            </div>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #profileMenu="matMenu" class="profile-custom-menu">
          <div class="profile_menu" style="display: flex;">
            <div class="avatar mr-2">
              <img
                src="/images/profile.jpg"
                alt="profile"
                *ngIf="!userName; else defaultLogo"
              />
              <ng-template #defaultLogo>
                <div
                  class="table-avatar mr-2"
                  style="
                    background-color: #005F8E;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  {{ getDefaultPhotoText(userName) }}
                </div>
              </ng-template>
            </div>
            <div class="text-left mr-2">
              <p class="mat-body-1 text fw-500 profile-text-color">
                {{ userName | titlecase }}
              </p>
              <p class="textXS grey-text">{{ userEmail }}</p>
            </div>
          </div>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
          <button mat-menu-item (click)="goToSettings()">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <div class="main-content body-bg">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
