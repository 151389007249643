import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/admin-portal/modules/user-management/services/user-management.service';
import { SignInService } from 'src/app/sign-in/services/sign-in.service';
import { CostCenterDailogComponent } from 'src/shared/components/dialog/admin-dialogs/cost-center-dialog/cost-center-dialog';
import { ExpenseDialogInLayoutComponent } from 'src/shared/components/dialog/admin-dialogs/expense-dialog-in-layout/expense-dialog-in-layout';
import { ProjectDialogComponent } from 'src/shared/components/dialog/admin-dialogs/project-dialog/project-dialog';
import { ResourceDialogComponent } from 'src/shared/components/dialog/admin-dialogs/resource-dialog/resource-dialog';
import { UserManagementDialogComponent } from 'src/shared/components/dialog/admin-dialogs/user-management-dialog/user-management-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  isSidebarOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  userName: string = '';
  userEmail: string = '';
  portalType: string = '';
  userID: string = '';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private signInService: SignInService,
    private userService: UserManagementService
  ) {}

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @Output() newItemEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userID = user?.userID;

    this.fetchUserDetails();
  }

  fetchUserDetails(): void {
    this.userService.getUserById(this.userID).subscribe({
      next: (res) => {
        const userData = res.data;
        this.userName = userData.name;
        this.userEmail = userData.email;
        this.portalType = userData.portolType;
      },
    });
  }

  handleToggleSidebar(event: any) {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.newItemEvent.emit(this.isSidebarOpen);
    this.isActive = !this.isActive;
  }
  onDrawerChange(opened: boolean): void {
    this.isDrawerOpen = opened;
  }

  toggleSidebarAndClass(event: any) {
    this.sidenav.toggle();
    this.handleToggleSidebar(event);
  }

  addAccount() {
    this.router.navigate(['/account/create']);
  }

  openAddExpenseDialog(): void {
    this.dialog.open(ExpenseDialogInLayoutComponent, {
      height: 'auto',
      width: '467px',
    });
  }

  openAddCostCentreDialog(): void {
    const dialogRef = this.dialog.open(CostCenterDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigate(['/cost-center']);
      }
    });
  }

  openAddProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigate(['/projects']);
      }
    });
  }

  openAddResourceDialog(): void {
    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigate(['/resources']);
      }
    });
  }

  openAddUserDialog(): void {
    const dialogRef = this.dialog.open(UserManagementDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigate(['/user-management']);
      }
    });
  }

  goToSettings() {
    this.router.navigate(['/profile/setting']);
  }

  logout() {
    this.signInService.logout();
    this.router.navigateByUrl('/sign-in');
  }

  getDefaultPhotoText(userName: string): string {
    if (!userName) return '';

    const firstChar = userName.charAt(0);
    const indexOfFirstSpace = userName.indexOf(' ');
    let secondChar = '';
    if (indexOfFirstSpace !== -1 && indexOfFirstSpace < userName.length - 1) {
      secondChar = userName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }
}
