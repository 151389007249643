<div style="display: flex; align-items: center">
  <span
    class="banner-message mat-body-1 text-center"
    matSnackBarLabel
    style="flex: 1"
  >
    {{ data.message }}
  </span>
  <span matSnackBarActions style="margin-left: 10px">
    <button
      mat-button
      matSnackBarAction
      (click)="dismissWithAction()"
      class="bg-transparent border-none color-white"
    >
      <span class="material-symbols-outlined" style="cursor: pointer">
        close
      </span>
    </button>
  </span>
</div>
