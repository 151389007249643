import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../shared/modules/angular-material/angular-material.module';
import { SidebarModule } from '../sidebar/sidebar.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [FormsModule, AngularMaterialModule, SidebarModule, RouterModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
